.base-day-card {
    margin: 25px;
}

.base-day-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.base-day-header span {
    font-size: 12px;
}

.base-comp {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    position: relative;
}

.icon-minus {
    position: absolute;
    right: 10px;
    font-size: 18px;
    color: #f00;
    cursor: pointer;
}

.base-ticket {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.btn-submit {
    display: flex;
    justify-content: center;
    margin: 15px 15px auto 0;
    float: right;
    width: 150px;
}

.btn-submit button {
    width: 100%;
    background: #666;
    border: none;
}

.btn-submit button:hover, .btn-submit button:active {
    background: #e6e6e6;
    color: #333;
}

.add-block-time{
    display: flex;
    justify-content: center;
    align-self: center;
    font-weight: 600;
    cursor: pointer;
}
.add-block-time i{
    margin-top: 3px;
}

.day-of-week {
    text-transform: capitalize;
    font-weight: 900;
}

.base-ticket,.day-status span{
    font-weight: 900;
}

.save-store-time {
    float: right;
    color: #fff;
    padding: 5px 40px !important;
    cursor: pointer;
    font-size: 14px !important;
}