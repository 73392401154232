.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body .ant-tabs-tab {
  font-weight: 400;
  text-align: center !important;
  display: inline-block;
}

body .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffd204 !important;
  text-align: center !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-inline.logout-tag {
  padding-right: 20px;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  color: #ffd204;
}

body {
  background-color: #f0f2f5;
}