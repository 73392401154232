.vertical-alignment-helper {
    height: 100vh;
    background-image: url("/Images/DDS_BG.jpg");
}

a:focus,
a:hover {
    color: #FFD401;
    text-decoration: none;
    outline: none !important;
}

.btn {
    font-size: 10px;
    padding: 0.3rem;
    white-space: normal;
}

.m0 {
    margin: 0;
}

.p0 {
    padding: 0;
}

.p-t-50 {
    padding-top: 50px;
}

.img-responsive {
    max-width: 100%;
    height: auto;
}

.top-right {
    position: absolute;
    right: 5px;
    top: 0;
}

/* Form CSS */
.form-control {
    padding: 15px 10px;
    font-size: 14px;
    color: #1c1c1c;
    background-color: #eae8e2;
    border: 0;
    height: 45px;
    border-radius: 0;
}

.btn.btn-save,
.btn-save {
    background: transparent url('/Images/cta-yellow.svg') center center no-repeat;
    /*min-width: 201px;*/
    min-height: 47px;
    font-size: 24px;
    color: #000;
    text-transform: uppercase;
    padding: 10px 20px;
    font-weight: 600;
    width: auto;
    background-size: cover;
}

.btn-save:focus,
.btn-save:hover {
    text-decoration: none;
    background-image: url('/Images/cta-white.svg');
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.3rem;
    outline: 0;
}

.modal-dialog {
    width: 450px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 100vh;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}

.modal-dialog .logo {
    background: url('/Images/logo-gomex.svg') no-repeat, none;
    background-size: contain;
    display: block;
    max-width: 110px;
    height: 110px;
    width: 110px;
    position: absolute;
    top: -50px;
    margin: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

.login-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.modal-body {
    padding-top: 80px;
}
.login-form .panel-body {
    margin-top: 18px;
}
.modal-dialog .login-form h2 {
    font-size: 24px;
    font-weight: 600;
    color: #ffd401;
    margin-bottom: 0;
}
.login-content {
    margin: 0 50px;
}
.login-content .form-group .input-group input {
    color: #fff;
    background-color: #000;
    padding-left: 10px;
    height: 35px;
}
.ant-input-affix-wrapper {
    height: 45px;
    border-radius: 4px;
    background-color: #000;
}

.save-btn{
    display: flex;
    justify-content: center;
    margin: 25px auto 0;
}

.form-group {
    margin-bottom: 1rem;
}

.ant-input { 
    border-radius: 4px;
}

.vertical-alignment-helper .panel-body .row .col-md-12 {
    padding: 0;
}