/* #content-wrapper {
    background-color: #f0f2f5;
    min-height: 100vh;
} */

header .gomex-logo { 
    margin-right: 30px;
    margin-left: 30px;
}

body .ant-switch-checked {
    background-color: #ffd204 !important;
}

body .ant-tabs-content-holder {
    background: #cecece;
    padding: 2rem;
}

button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled) {
    margin: 0 3px;
}