.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 100px;
}

.search-bar {
    flex: 1;
}

.blank-space {
    flex: 1;
}

.title-store {
    text-align: center;
    flex: 2;
}

.tab-content {
    padding: 0 15px;
}

.tab-content .ant-tabs-tab {
    background: #e6e6e6;
    border-radius: 5px 5px 0 0;
    color: #5a6268;
    border: 1px solid #5a6268;
    min-width: 120px;
    margin: 0 auto;
    text-align: center;
}

.tab-content .ant-tabs-tab-active {
    background: #333;
    border-radius: 5px 5px 0 0;
    color: #fff;
    margin-right: 0;
    border: 1px solid #5a6268;
}

.tab-content .ant-tabs-nav .ant-tabs-tab:hover {
    color: #6c757d;
}

.tab-content .ant-tabs-nav .ant-tabs-tab:active {
    border-bottom: none;
}

.store-switch span {
    margin: 0 5px;
    font-size: 12px;
}

.setting-content {
    display: flex;
}

.left-side {
    flex: 1;
    border-right: 1px solid #333;
    padding: 15px 30px;
}

.left-side.store-group {
    border-right: none;
}

.left-side p>span {
    font-weight: bold;
}

.right-side {
    flex: 1;
    padding: 15px 30px;
}

.setting-header {
    display: flex;
    justify-content: space-between;
}

.footer-bottom {
    margin: 0 auto;
    text-align: center;
}

.integrate {
    display: flex;
    justify-content: space-between;
}

.btn {
    display: flex;
    justify-content: flex-end;
}

.btn button {
    background: #666;
    border: none;
}

.btn-delete button {
    background: #666;
    border: none;
    color: #fff;
}

.btn-delete button:hover {
    background: #dedede;
    color: #000;
}

.footer-bottom p {
    font-size: 12px;
}

.base-tab {
    display: flex;
    flex-wrap: wrap;
}

.btn-delete {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}