.loadingPanel {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000000 url('/Images/loader.gif') 50% 50% no-repeat;
    opacity: 0.8;
}
.loadingPanel.d-block{
    display: block;
}
.loadingPanel.d-none{
    display: none;
}